import React, { useEffect, useState } from "react";
import { Button, CssBaseline, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CustomSelect from "../../Components/Select/CustomSelect";
import PdfPicker from "../../Components/FilePicker/pdfPicker";
import { apiEndPoint } from "../../constant/ApiEndPoint";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Service from "../../service/Services";

import {
  getCourseSectionArtifact,
  getCourseSectionArtifactByParent,
  saveCourseAssignment,
  saveCourseQuestion,
  saveCourseSection,
  uploadCourseSectionImage,
  uploadCourseSectionPdf,
  uploadCourseSubSectionLanguageVideo,
  uploadCourseSubSectionVideo,
  getCourseById,
  getCourseSectionList,
  resetArtifact,
  questionFormLoader,
  uploadCourseSubSectionLanguagePdf,
  getCourseSectionArtifactByParentPdf,
} from "../../redux/action/courseAction";
import { useDispatch, useSelector } from "react-redux";
import ContentImagePicker from "../../Components/FilePicker/ContentImagePicker";
import { Box } from "@mui/system";
import { MediaCard } from "./courseOverViewVideo";
import ExerciseSection from "./ExerciseSection";
import FinalAssessmentSection from "./FinalAssessmentSection";
import { useParams } from "react-router-dom";
import VideoPicker from "../../Components/FilePicker/VideoPicker";
import SnackbarAlert from "../../Components/Snackbar/SnackbarAlert";
import MediaLoader from "../../Components/Loader/mediaLoader";
import QuestionLoader from "../../Components/Loader/questionLoader";
import useSnackbar from "../../Components/Snackbar/useSnackbar";
import { isValidImage, isValidPdf, isValidVideo } from "../../utils/validationFileType";
import FitbitIcon from '@mui/icons-material/Fitbit';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import AssessmentIcon from '@mui/icons-material/Assessment';
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "30px",
  },
  videoUpdate: {
    flex:" 0 0 auto",
    display: "flex",
    padding: "18px",
    alignItems: "center",
    justifyContent:"center",
    marginRight: "400px",
  },
  pdfUpdate: {
    flex:" 0 0 auto",
    display: "flex",
    padding: "90px",
    alignItems: "center",
    justifyContent:"center",
    marginRight: "400px",
  },
  videoButton: {

  }
}));

const optionArray = [
  {
    key: "0",
    label: "Exercise",
    value: 0,
    icon: "/public/icons/exercise.png",
  },
  {
    key: "1",
    label: "Video",
    value: 1,
    icon: "/public/icons/video.png",
  },
  {
    key: "2",
    label: "Image",
    value: 2,
    icon: "/public/icons/image.png",
  },
  {
    key: "3",
    label: "PDF",
    value: 3,
    icon: "/public/icons/pdf.png",
  },
  {
    key: "4",
    label: "Final Assessment",
    value: 4,
    icon: "/public/icons/assessment.png",
  },
];
interface Props {
  parentId?: any;
  closeModal?: any;
  togleEvent?: any;
  isModal?: any;
  rowData?: any;
  data: any;
  itemIndex?: any;
  courseReducer?: any;
  courseSectionId?: any;
  handleCloseModal?: any;
  executeScroll?: any;
}

const SubSectionContent: React.FC<Props> = (props, { parentId }) => {
  const Snackbar = useSnackbar();

  let { togleEvent, isModal, data, itemIndex, executeScroll } = props;

  let { courseQuestionList, courseSection } = data;
  let { id } = useParams();
  let assignmentId = courseSection?.assignmentId;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [contentType, setContentType] = useState<any>(null);
  const [selectedIcon, setSelectedIcon] = useState<string>("");
  let [selectedQuestions, setSelectedQuestions] = useState<any>(null);
  let [selectedAssessment, setSelectedAssessment] = useState<any>(null);
  const [questionSnackbarAlert, setQuestionSnackbarAlert] = useState(false);
  const [pdfContent, setPdfContent] = useState<any>(null);
  const [videoPreview, setVideoPreview]: any = React.useState();
  const [videoPath, setVideoPath]: any = React.useState();
  const [selectedVideo, setSelectedVideo] = useState<any>(null);
  const [inputData, setInputData]: any = useState({
    selectedLan: null,
  });
  const [allLanguages, setAllLanguages] = useState([
    {
      typeId: 1,
      typeName: "English",
    },
    {
      typeId: 2,
      typeName: "Hindi",
    },
    {
      typeId: 3,
      typeName: "Marathi",
    },
  ]);
  const [allLanguagesPdf, setAllLanguagesPdf] = useState([
    {
      typeId: 1,
      typeName: "English",
    },
    {
      typeId: 2,
      typeName: "Hindi",
    },
    {
      typeId: 3,
      typeName: "Marathi",
    },
  ]);
  const [rowData, setRowData]: any = useState({
    selectedType: null,
    selectedLan: null,
  });

  const [filePreview, setFilePreview] = useState<any>(null);
  const [imageContent, setImageContent] = useState<any>(null);
  let [imagePreview, setImagePreview] = useState<any>(null);

  const [questionList, setQuestionList] = useState<any>(courseQuestionList ? [...courseQuestionList] : []);
  const [assessmentList, setAssessmentList] = useState<any>(null);
  const [parentArtifactId, setParentArtifactId] = useState(null);
  const { artifactFile, artifactVideos ,artifactPdfs } = useSelector((state: Props) => state.courseReducer);
  const { courseMediaLoader, questionLoader } = useSelector((state: any) => state.loaderReducer);
  let uploadedPdfs = artifactPdfs && artifactPdfs.length > 0;
  let uploadedStatus = artifactVideos && artifactVideos.length > 0;
  const handleStateChange = (field: any, value: any) => {
    setContentType(value);
  };
  useEffect(() => {
    if (togleEvent !== 0 && isModal) {
      handleSave();
    }
    // eslint-disable-next-line
  }, [togleEvent, isModal]);
  useEffect(() => {
    if (contentType === 2 ) {
      if (parentArtifactId) {
        let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT}=${parentArtifactId}`;
        dispatch(getCourseSectionArtifact(uri));
      }
    } else if (contentType === 1 ) {
      if (parentArtifactId) {
        let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACT_BY_PARENT}=${parentArtifactId}`;
        dispatch(getCourseSectionArtifactByParent(uri));
      }else{
          let courseSectionId = courseSection.courseSectionId;
          let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACTS}=${courseSectionId}`;
          dispatch(getCourseSectionArtifactByParentPdf(uri));
      }
    }
  }, [contentType, parentArtifactId, dispatch]);
  let courseSectionId = courseSection.courseSectionId;
  useEffect(() => {
    let uri = `${apiEndPoint.GET_COURSE_SECTION_ARTIFACTS}=${courseSectionId}`;
    dispatch(getCourseSectionArtifactByParentPdf(uri));
  }, [courseSectionId, dispatch]);
  useEffect(() => {
    if (id) {
      let uri = `${apiEndPoint.GET_COURSE_BY_ID}=${id}`;
      dispatch(getCourseById(uri));
    }
  }, [id, dispatch]);
  useEffect(() => {
    Service.postApi("/content/artifactLanguageAll").then((res) => {
      if (res.httpCode === 200) {
        const result = res.data;
        setAllLanguages(result);
        setAllLanguagesPdf(result)
      }
    });
  }, []);

  useEffect(() => {
    if (data.courseQuestionList && data.courseQuestionList.length > 0) {
      if (data.courseAssignment.isaFinalAssessment === "Y") {
        setAssessmentList(data.courseQuestionList);
        setContentType(4);
      } else {
        setQuestionList(data.courseQuestionList);
        setContentType(0);
      }
    } else if (data.courseArtifactList && data.courseArtifactList.length > 0) {
      setContentType(data.courseArtifactList[0].artifactTypeId);
    }
    let tdcartifactId =
      data.courseArtifactList && data.courseArtifactList[0].artifactTypeId
        ? data.courseArtifactList[0].tdcartifactId
        : null;
    setParentArtifactId(tdcartifactId);
    // eslint-disable-next-line
  }, [itemIndex]);

  const renderContent = () => {
    switch (contentType) {
      case 0:
        return (
          <>
            <QuestionLoader visible={questionLoader} />
            <ExerciseSection
              data={data}
              questionList={questionList}
              handleSelectedQuestions={handleSelectedQuestions}
              scrollTop={executeScroll}
            />
          </>
        );
      case 1:
        return <>{renderVideo()}</>;
      case 2:
        return <>{renderImage()}</>;
      case 3:
        return <>{renderPDF()}</>;
      case 4:
        return (
          <>
            <QuestionLoader visible={questionLoader} />
            <div>
              <FinalAssessmentSection
                data={data}
                assessmentList={assessmentList}
                handleSelectedAssessment={handleSelectedAssessment}
                scrollTop={executeScroll}
              />
            </div>
          </>
        );

      default:
        return <>Please Select an Option</>;
    }
  };

  const handleSelectedQuestions = (questions) => {
    setSelectedQuestions(questions);
  };

  const handleSelectedAssessment = (questions) => {
    setSelectedAssessment(questions);
  };

  const handleSaveCourseQuestion = async (data, list, uri, type) => {
    dispatch(questionFormLoader(true));
    let assignmentId = data[0].assignmentId;
    // list.forEach((item, iteration) => {
    //   item = { ...item, courseAssignmentId: assignmentId };
    //   dispatch(saveCourseQuestion(uri, item));
    //   if (iteration === list.length - 1) {
    //     Snackbar.show("Save Successfully", "success");
    //     dispatch(questionFormLoader(false));
    //     setTimeout(() => {
    //       setQuestionSnackbarAlert(false);
    //       props.handleCloseModal(false);
    //     }, 2000);
    //   }
    // });
    let count = 0;
    for (let item of list) {
      count++;
      item = { ...item, courseAssignmentId: assignmentId };
      let response: any = await dispatch(saveCourseQuestion(uri, item));
      if (count === list.length) {
        setTimeout(() => {
          Snackbar.show("Save Successfully", "success");
          dispatch(questionFormLoader(false));
          fetchCourseSectionList();
          setQuestionSnackbarAlert(false);
          props.handleCloseModal(false);
        }, 1000);
      }
    }
  };

  const renderSnackbar = (message, visible, type) => {
    if (visible) {
      return <SnackbarAlert message={message} isOpen={visible} type={type} />;
    }
  };

  const fetchCourseSectionList = () => {
    let uri = `${apiEndPoint.GET_COURSE_SECTION_LIST}=${id}`;
    dispatch(getCourseSectionList(uri));
  };
  const updateVideo =async () => {
    let courseSectionId = courseSection.courseSectionId;
    let uri = "";
    let response: any = null;
    let newContentType = contentType === 0 ? "0" : contentType;
    if (!newContentType) {
      // return alert("Please Select Content!");
      Snackbar.show("Please Select Content!", "error");
    }

    switch (newContentType) {
      case "0":
        if (selectedQuestions && selectedQuestions.length > 0) {
          dispatch(questionFormLoader(true));
          uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
          let questionList = selectedQuestions;
          let assignmentId = courseSection?.assignmentId;
          if (assignmentId > 0) {
            // questionList.forEach((item, iteration) => {
            //   dispatch(saveCourseQuestion(uri, item));
            //   if (iteration === questionList.length - 1) {
            //     Snackbar.show("Save Successfully ", "success");
            //     dispatch(questionFormLoader(false));
            //     setTimeout(() => {
            //       fetchCourseSectionList();
            //       setQuestionSnackbarAlert(false);
            //       props.handleCloseModal(false);
            //     }, 2000);
            //   }
            // });
            let count = 0;
            for (let item of questionList) {
              count++;
              // item = { ...item, courseAssignmentId: assignmentId };
              let response: any = await dispatch(saveCourseQuestion(uri, item));
              if (count == questionList.length) {
                setTimeout(() => {
                  Snackbar.show("Save Successfully ", "success");
                  dispatch(questionFormLoader(false));
                  fetchCourseSectionList();
                  setQuestionSnackbarAlert(false);
                  props.handleCloseModal(false);
                }, 1000);
              }
            }
          } else {
            dispatch(questionFormLoader(true));
            uri = `${apiEndPoint.SAVE_COURSE_ASSIGNMENT}`;
            let args = {
              courseAssignmentId: 0,
              courseId: id,
              details: `Assignment - ${id}`,
              maxNoQuestions: questionList.length,
            };
            response = await dispatch(saveCourseAssignment(uri, args));
            if (response.status) {
              let newAssignmentId = response.result[0].courseAssignmentId;
              let reqSection = {
                allowPublicView: courseSection.allowPublicView,
                assignmentId: newAssignmentId,
                courseId: id,
                courseSectionId: courseSection.courseSectionId,
                parentSectionId: courseSection.parentSectionId,
                sectionDetails: courseSection.sectionDetails,
                sectionDurationHours: courseSection.sectionDurationHours,
                sectionDurationMinutes: courseSection.sectionDurationMinutes,
                sectionIndex: courseSection.sectionIndex,
                sectionTitle: courseSection.sectionTitle,
              };
              uri = `${apiEndPoint.SAVE_COURSE_SECTION}`;
              response = await dispatch(saveCourseSection(uri, reqSection, "args2"));
              if (response.status) {
                uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
                await handleSaveCourseQuestion(response.result, questionList, uri, "Exercise");
                // fetchCourseSectionList();
              }
            }
          }
        } else {
          Snackbar.show("Please Add or Upload Exercise of Sub Section", "error");
        }
        break;
      case 1:
        if (!inputData.selectedLan) {
          // alert("Please Select Language  First");
          Snackbar.show("Please Select Language  First", "error");
          return false;
        } else if (!selectedVideo) {
          // alert("Please Select Video!");
          Snackbar.show("Please Select Video!", "error");
          return false;
        } else if (inputData.selectedLan === 0) {
          if (uploadedStatus) {
            // alert("English Video already Uploaded!");
            Snackbar.show("English Video already Uploaded!", "error");

            return false;
          }
          let languageId = inputData.selectedLan;
          uri = `${apiEndPoint.UPLOAD_COURSE_SUB_SECTION_VIDEO}=${courseSectionId}&languageId=${languageId}`;
          response = await dispatch(uploadCourseSubSectionVideo(uri, selectedVideo));
          if (response.status) {
            Snackbar.show("Video Uploaded Successfully!", "success");
            fetchCourseSectionList();
            props.closeModal();
          } else {
            Snackbar.show(response.result, "success");
          }
          // setTimeout(() => {
          //   Snackbar.show("Video uploded!", "success");
          // }, 1500);
          // setTimeout(() => {

          // }, 2000);
        } else {
          let existingLanguage: any = [];
          artifactVideos?.forEach((item: any, i: any) => {
            if (item.languageId === inputData.selectedLan) {
              existingLanguage = [...existingLanguage, item];
              return {
                existingLanguage,
              };
            }
          });

          // if (existingLanguage && existingLanguage.length > 0) {
          //   // alert("Please Choose another Language, This Language already exist.");
          //   Snackbar.show("Please Choose another Language, This Language already exist.", "error");
          //   return false;
          // }
          if (!parentArtifactId) {
            // alert("Please Choose English Language video first");
            Snackbar.show("Please Choose English Language video first", "error");
          } else {
            if (parentArtifactId) {
              let languageId = inputData.selectedLan;
              uri = `${apiEndPoint.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_VIDEO}=${courseSectionId}&languageId=${languageId}&parentArtifactId=${parentArtifactId}`;
              await dispatch(uploadCourseSubSectionLanguageVideo(uri, selectedVideo));
              dispatch(resetArtifact());
              // fetchCourseSectionList();
              // props.closeModal();
              Snackbar.show("Video Uploaded Successfully!", "success");
              setTimeout(() => {
                fetchCourseSectionList();
                props.closeModal();
              }, 1000);
            }
          }
        }
        break;
      case 2:
        if (imageContent) {
          uri = `${apiEndPoint.UPLOAD_COURSE_SECTION_IMAGE}=${courseSectionId}`;
          response = await dispatch(uploadCourseSectionImage(uri, imageContent));
          if (response.status) {
            Snackbar.show("Image Uploaded Successfully!", "success");
            dispatch(resetArtifact());
            setTimeout(() => {
              setImagePreview(null);
              setImageContent(null);
              fetchCourseSectionList();
              props.closeModal(false);
            }, 1000);
          } else {
            Snackbar.show(response.result, "error");
          }
        } else {
          Snackbar.show("Please Add Image of Sub Section", "error");
        }

        break;
        case 3:
        if (!rowData.selectedLan) {
          Snackbar.show("Please Select Language  First", "error");
          return false;
        } else if (!pdfContent) {  
          Snackbar.show("Please Select PDF!", "error");
          return false;
        } else if (rowData.selectedLan === 0) {
          if (uploadedPdfs) { 
            Snackbar.show("English PDF already Uploaded!", "error");
            return false;
          }
          let languageId = rowData.selectedLan;
          uri = `${apiEndPoint.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_PDF}=${courseSectionId}&languageId=${languageId}`;
          await dispatch(uploadCourseSubSectionLanguagePdf(uri, pdfContent));
          dispatch(resetArtifact());
          // fetchCourseSectionList();
          // props.closeModal();
         
          if (response.status) {
            Snackbar.show("PDF Uploaded Successfully!", "success");
            
              fetchCourseSectionList();
              props.closeModal(false);
          
          } else {
            Snackbar.show(response.result, "success");
          }
        } else {
          let existingLanguage: any = [];
          artifactPdfs?.forEach((item: any, i: any) => {
            if (item.languageId === rowData.selectedLan) {
              existingLanguage = [...existingLanguage, item];
              return {
                existingLanguage,
              };
            }
          });
          // if (existingLanguage && existingLanguage.length > 0) {
          //   // alert("Please Choose another Language, This Language already exist.");
          //   Snackbar.show("Please Choose another Language, This Language already exist.", "error");
          //   return false;
          // }
          
          if (!parentArtifactId) {
            // alert("Please Choose English Language video first");
            Snackbar.show("Please Choose English Language PDF first", "error");
          } else {
            if (parentArtifactId) {
              let languageId = rowData.selectedLan;
              uri = `${apiEndPoint.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_PDF}=${courseSectionId}&languageId=${languageId}`;
              await dispatch(uploadCourseSubSectionLanguagePdf(uri, pdfContent));
              dispatch(resetArtifact());
              // fetchCourseSectionList();
              // props.closeModal();
              Snackbar.show("PDF Uploaded Successfully!", "success");
              setTimeout(() => {
                fetchCourseSectionList();
                props.closeModal();
              }, 1000);
            }
          }
        }
        break;
        


      case 4:
        if (selectedAssessment && selectedAssessment.length > 0) {
          uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
          let assessmentList = selectedAssessment;
          let assignmentId = courseSection?.assignmentId;
          if (assignmentId) {
            // assessmentList.forEach((item, iteration) => {
            //   dispatch(saveCourseQuestion(uri, item));
            //   if (iteration === assessmentList.length - 1) {
            //     Snackbar.show("Save Successfully", "success");
            //     setTimeout(() => {
            //       fetchCourseSectionList();
            //       setQuestionSnackbarAlert(false);
            //     }, 2000);
            //   }
            // });
            let count = 0;
            for (let item of assessmentList) {
              count++;
              // item = { ...item, courseAssignmentId: assignmentId };
              let response: any = await dispatch(saveCourseQuestion(uri, item));
              if (count == assessmentList.length) {
                setTimeout(() => {
                  Snackbar.show("Save Successfully ", "success");
                  dispatch(questionFormLoader(false));
                  fetchCourseSectionList();
                  setQuestionSnackbarAlert(false);
                  props.handleCloseModal(false);
                }, 1000);
              }
            }
          } else {
            dispatch(questionFormLoader(true));
            uri = `${apiEndPoint.SAVE_COURSE_FINAL_ASSIGNMENT}`;
            let args = {
              courseAssignmentId: 0,
              courseId: id,
              details: `Assignment - ${id}`,
              maxNoQuestions: assessmentList.length,
            };
            response = await dispatch(saveCourseAssignment(uri, args));
            if (response.status) {
              let newAssignmentId = response.result[0].courseAssignmentId;
              let reqSection = {
                allowPublicView: courseSection.allowPublicView,
                assignmentId: newAssignmentId,
                courseId: id,
                courseSectionId: courseSection.courseSectionId,
                parentSectionId: courseSection.parentSectionId,
                sectionDetails: courseSection.sectionDetails,
                sectionDurationHours: courseSection.sectionDurationHours,
                sectionDurationMinutes: courseSection.sectionDurationMinutes,
                sectionIndex: courseSection.sectionIndex,
                sectionTitle: courseSection.sectionTitle,
              };
              uri = `${apiEndPoint.SAVE_COURSE_SECTION}`;
              response = await dispatch(saveCourseSection(uri, reqSection, "args2"));
              if (response.status) {
                uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
                await handleSaveCourseQuestion(response.result, assessmentList, uri, "Final Assessment");
                // dispatch(questionFormLoader(false));
                // fetchCourseSectionList();
              }
            }
          }
        } else {
          Snackbar.show("Please Add or Upload Final Assessment of Sub Section", "error");

          dispatch(questionFormLoader(false));
        }
        break;
    }
  }
  const handleSave = async () => {
    let courseSectionId = courseSection.courseSectionId;
    let uri = "";
    let response: any = null;
    let newContentType = contentType === 0 ? "0" : contentType;
    if (!newContentType) {
      // return alert("Please Select Content!");
      Snackbar.show("Please Select Content!", "error");
    }

    switch (newContentType) {
      case "0":
        if (selectedQuestions && selectedQuestions.length > 0) {
          dispatch(questionFormLoader(true));
          uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
          let questionList = selectedQuestions;
          let assignmentId = courseSection?.assignmentId;
          if (assignmentId > 0) {
            // questionList.forEach((item, iteration) => {
            //   dispatch(saveCourseQuestion(uri, item));
            //   if (iteration === questionList.length - 1) {
            //     Snackbar.show("Save Successfully ", "success");
            //     dispatch(questionFormLoader(false));
            //     setTimeout(() => {
            //       fetchCourseSectionList();
            //       setQuestionSnackbarAlert(false);
            //       props.handleCloseModal(false);
            //     }, 2000);
            //   }
            // });
            let count = 0;
            for (let item of questionList) {
              count++;
              // item = { ...item, courseAssignmentId: assignmentId };
              let response: any = await dispatch(saveCourseQuestion(uri, item));
              if (count == questionList.length) {
                setTimeout(() => {
                  Snackbar.show("Save Successfully ", "success");
                  dispatch(questionFormLoader(false));
                  fetchCourseSectionList();
                  setQuestionSnackbarAlert(false);
                  props.handleCloseModal(false);
                }, 1000);
              }
            }
          } else {
            dispatch(questionFormLoader(true));
            uri = `${apiEndPoint.SAVE_COURSE_ASSIGNMENT}`;
            let args = {
              courseAssignmentId: 0,
              courseId: id,
              details: `Assignment - ${id}`,
              maxNoQuestions: questionList.length,
            };
            response = await dispatch(saveCourseAssignment(uri, args));
            if (response.status) {
              let newAssignmentId = response.result[0].courseAssignmentId;
              let reqSection = {
                allowPublicView: courseSection.allowPublicView,
                assignmentId: newAssignmentId,
                courseId: id,
                courseSectionId: courseSection.courseSectionId,
                parentSectionId: courseSection.parentSectionId,
                sectionDetails: courseSection.sectionDetails,
                sectionDurationHours: courseSection.sectionDurationHours,
                sectionDurationMinutes: courseSection.sectionDurationMinutes,
                sectionIndex: courseSection.sectionIndex,
                sectionTitle: courseSection.sectionTitle,
              };
              uri = `${apiEndPoint.SAVE_COURSE_SECTION}`;
              response = await dispatch(saveCourseSection(uri, reqSection, "args2"));
              if (response.status) {
                uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
                await handleSaveCourseQuestion(response.result, questionList, uri, "Exercise");
                // fetchCourseSectionList();
              }
            }
          }
        } else {
          Snackbar.show("Please Add or Upload Exercise of Sub Section", "error");
        }
        break;
      case 1:
        if (!inputData.selectedLan) {
          // alert("Please Select Language  First");
          Snackbar.show("Please Select Language  First", "error");
          return false;
        } else if (!selectedVideo) {
          // alert("Please Select Video!");
          Snackbar.show("Please Select Video!", "error");
          return false;
        } else if (inputData.selectedLan === 1) {
          if (uploadedStatus) {
            // alert("English Video already Uploaded!");
            Snackbar.show("English Video already Uploaded!", "error");

            return false;
          }
          let languageId = inputData.selectedLan;
          uri = `${apiEndPoint.UPLOAD_COURSE_SUB_SECTION_VIDEO}=${courseSectionId}&languageId=${languageId}`;
          response = await dispatch(uploadCourseSubSectionVideo(uri, selectedVideo));
          if (response.status) {
            Snackbar.show("Video Uploaded Successfully!", "success");
            fetchCourseSectionList();
            props.closeModal();
          } else {
            Snackbar.show(response.result, "success");
          }
          // setTimeout(() => {
          //   Snackbar.show("Video uploded!", "success");
          // }, 1500);
          // setTimeout(() => {

          // }, 2000);
        } else {
          let existingLanguage: any = [];
          artifactVideos?.forEach((item: any, i: any) => {
            if (item.languageId === inputData.selectedLan) {
              existingLanguage = [...existingLanguage, item];
              return {
                existingLanguage,
              };
            }
          });

          if (existingLanguage && existingLanguage.length > 0) {
            // alert("Please Choose another Language, This Language already exist.");
            Snackbar.show("Please Choose another Language, This Language already exist.", "error");
            return false;
          }
          if (!parentArtifactId) {
            // alert("Please Choose English Language video first");
            Snackbar.show("Please Choose English Language video first", "error");
          } else {
            if (parentArtifactId) {
              let languageId = inputData.selectedLan;
              uri = `${apiEndPoint.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_VIDEO}=${courseSectionId}&languageId=${languageId}&parentArtifactId=${parentArtifactId}`;
              await dispatch(uploadCourseSubSectionLanguageVideo(uri, selectedVideo));
              dispatch(resetArtifact());
              // fetchCourseSectionList();
              // props.closeModal();
              Snackbar.show("Video Uploaded Successfully!", "success");
              setTimeout(() => {
                fetchCourseSectionList();
                props.closeModal();
              }, 1000);
            }
          }
        }
        break;
      case 2:
        if (imageContent) {
          uri = `${apiEndPoint.UPLOAD_COURSE_SECTION_IMAGE}=${courseSectionId}`;
          response = await dispatch(uploadCourseSectionImage(uri, imageContent));
          if (response.status) {
            Snackbar.show("Image Uploaded Successfully!", "success");
            dispatch(resetArtifact());
            setTimeout(() => {
              setImagePreview(null);
              setImageContent(null);
              fetchCourseSectionList();
              props.closeModal(false);
            }, 1000);
          } else {
            Snackbar.show(response.result, "error");
          }
        } else {
          Snackbar.show("Please Add Image of Sub Section", "error");
        }

        break;
      case 3:
        if (!rowData.selectedLan) {
          Snackbar.show("Please Select Language  First", "error");
          return false;
        } else if (!pdfContent) {  
          Snackbar.show("Please Select PDF!", "error");
          return false;
        } else if (rowData.selectedLan === 1) {
          if (uploadedPdfs) { 
            Snackbar.show("English PDF already Uploaded!", "error");
            return false;
          }
          let pdfLanguageId = rowData.selectedLan;
          uri = `${apiEndPoint.UPLOAD_COURSE_SECTION_PDF}=${courseSectionId}&languageId=${pdfLanguageId}`;
          response = await dispatch(uploadCourseSectionPdf(uri, pdfContent));
          if (response.status) {
            Snackbar.show("PDF Uploaded Successfully!", "success");
            
              fetchCourseSectionList();
              props.closeModal(false);
          
          } else {
            Snackbar.show(response.result, "success");
          }
        } else {
          let existingLanguage: any = [];
          artifactPdfs?.forEach((item: any, i: any) => {
            if (item.languageId === rowData.selectedLan) {
              existingLanguage = [...existingLanguage, item];
              return {
                existingLanguage,
              };
            }
          });
          if (existingLanguage && existingLanguage.length > 0) {
            // alert("Please Choose another Language, This Language already exist.");
            Snackbar.show("Please Choose another Language, This Language already exist.", "error");
            return false;
          }
          
          if (!parentArtifactId) {
            // alert("Please Choose English Language video first");
            Snackbar.show("Please Choose English Language PDF first", "error");
          } else {
            if (parentArtifactId) {
              let languageId = rowData.selectedLan;
              uri = `${apiEndPoint.UPLOAD_COURSE_SUB_SECTION_LANGUAGE_PDF}=${courseSectionId}&languageId=${languageId}`;
              await dispatch(uploadCourseSubSectionLanguagePdf(uri, pdfContent));
              dispatch(resetArtifact());
              // fetchCourseSectionList();
              // props.closeModal();
              Snackbar.show("PDF Uploaded Successfully!", "success");
              setTimeout(() => {
                fetchCourseSectionList();
                props.closeModal();
              }, 1000);
            }
          }
        }
        break;
        

      case 4:
        if (selectedAssessment && selectedAssessment.length > 0) {
          uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
          let assessmentList = selectedAssessment;
          let assignmentId = courseSection?.assignmentId;
          if (assignmentId) {
            // assessmentList.forEach((item, iteration) => {
            //   dispatch(saveCourseQuestion(uri, item));
            //   if (iteration === assessmentList.length - 1) {
            //     Snackbar.show("Save Successfully", "success");
            //     setTimeout(() => {
            //       fetchCourseSectionList();
            //       setQuestionSnackbarAlert(false);
            //     }, 2000);
            //   }
            // });
            let count = 0;
            for (let item of assessmentList) {
              count++;
              // item = { ...item, courseAssignmentId: assignmentId };
              let response: any = await dispatch(saveCourseQuestion(uri, item));
              if (count == assessmentList.length) {
                setTimeout(() => {
                  Snackbar.show("Save Successfully ", "success");
                  dispatch(questionFormLoader(false));
                  fetchCourseSectionList();
                  setQuestionSnackbarAlert(false);
                  props.handleCloseModal(false);
                }, 1000);
              }
            }
          } else {
            dispatch(questionFormLoader(true));
            uri = `${apiEndPoint.SAVE_COURSE_FINAL_ASSIGNMENT}`;
            let args = {
              courseAssignmentId: 0,
              courseId: id,
              details: `Assignment - ${id}`,
              maxNoQuestions: assessmentList.length,
            };
            response = await dispatch(saveCourseAssignment(uri, args));
            if (response.status) {
              let newAssignmentId = response.result[0].courseAssignmentId;
              let reqSection = {
                allowPublicView: courseSection.allowPublicView,
                assignmentId: newAssignmentId,
                courseId: id,
                courseSectionId: courseSection.courseSectionId,
                parentSectionId: courseSection.parentSectionId,
                sectionDetails: courseSection.sectionDetails,
                sectionDurationHours: courseSection.sectionDurationHours,
                sectionDurationMinutes: courseSection.sectionDurationMinutes,
                sectionIndex: courseSection.sectionIndex,
                sectionTitle: courseSection.sectionTitle,
              };
              uri = `${apiEndPoint.SAVE_COURSE_SECTION}`;
              response = await dispatch(saveCourseSection(uri, reqSection, "args2"));
              if (response.status) {
                uri = `${apiEndPoint.SAVE_COURSE_QUESTIONS}`;
                await handleSaveCourseQuestion(response.result, assessmentList, uri, "Final Assessment");
                // dispatch(questionFormLoader(false));
                // fetchCourseSectionList();
              }
            }
          }
        } else {
          Snackbar.show("Please Add or Upload Final Assessment of Sub Section", "error");

          dispatch(questionFormLoader(false));
        }
        break;
    }
  };

  const renderVideo = () => {
    return (<>
      <div>
        {" "}
      
        <div>
          <Box sx={{ flexGrow: 1 }} style={{ marginTop: 10 }}>
            <div style={{ maxWidth: 420 }}>
              Uploaded Videos:
              {artifactVideos?.map((item) => {
                return <MediaCard data={item} videoPath={item.path} lang={item.languageId} name={item.name} />;
              })}
            </div>
            <Grid container spacing={2}>
              <div style={{ width: "100%", marginTop: 20 }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label" style={{ marginTop: 20 }}></InputLabel>
                  <Select
                    id="demo-simple-select"
                    value={inputData.selectedLan}
                    placeholder="Please language"
                    onChange={(event) => {
                      handleChangeLanguage(event.target.value);
                    }}
                  >
                    {allLanguages?.map((item, i): any => (
                      <MenuItem value={item?.typeId}>{item.typeName}</MenuItem>
                    ))}
                  </Select>
                  <div style={{ marginTop: 20 }}></div>
                </FormControl>
                <MediaLoader visible={courseMediaLoader} />
              </div>

              <VideoPicker handlePickImage={(e) => handleVideoSelect(e)} video={videoPreview} videoPath={videoPath} />
            </Grid>
          </Box>
        </div>
      </div>
      <div className={classes.videoUpdate}>
        <Button style={{marginLeft:"184px" ,color: "#fff", backgroundColor:"green",marginTop:"-51px",}}
         onClick={updateVideo}
         >update</Button>
        </div>
      </>);
  };

  const renderImage = () => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        

        <Grid style={{ width: "40%" }}>
          <ContentImagePicker
            handlePickImage={handleSaveImage}
            image={imagePreview}
            artifactFile={artifactFile}
            // disabled={imageContent ? true : false}
            disabled={artifactFile ? true : false}
          />
        </Grid>
        <MediaLoader visible={courseMediaLoader} />
      </div>
    );
  };
  const renderPDF = () => {
    return (
      <><div
        style={{
          display: "flex",
        }}
      >
        <form style={{ width: "100%" }}>
          <Grid style={{ width: "100%", height: "70%" }}>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label" style={{ marginTop: 20 }}></InputLabel>
              <Select
                id="demo-simple-select"
                value={rowData.selectedLan}
                placeholder="Please language"
                onChange={(event) => {
                  handleChangeLanguages(event.target.value);
                } }
              >
                {allLanguagesPdf?.map((item, i): any => (
                  <MenuItem value={item?.typeId}>{item.typeName}</MenuItem>
                ))}
              </Select>
              <div style={{ marginTop: 20 }}></div>
            </FormControl>
            <MediaLoader visible={courseMediaLoader} />
            <PdfPicker handlePickPdf={handleSavePdf} pdf={filePreview} artifactFile={artifactFile} />
          </Grid>
          <MediaLoader visible={courseMediaLoader} />
        </form>
      </div>
       <div className={classes.pdfUpdate}>
          <Button style={{ marginLeft: "184px", color: "#fff", backgroundColor: "green", marginTop: "-70px", }}
            onClick={updateVideo}
          >update</Button>
        </div>  
        </>
    );
  };
  const handleVideoSelect = (e: any) => {
    try {
      if (e.target?.files && e.target?.files[0]) {
        let isValid = isValidVideo(e.target.files[0].name);
        if (!isValid) {
          return false;
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        let file = e.target.files[0];
        setVideoPreview(e.target.files[0]);
        setVideoPath(file.name);
        setSelectedVideo(formData);
      }
    } catch (error: any) {
      alert(error.message);
    }
  };
  const handleChangeLanguage = (value) => {
    let existingLanguage: any = [];

    allLanguages.forEach((item: any, i: any) => {
      if (item.languageId === value) {
        existingLanguage = [...existingLanguage, item];
        return existingLanguage;
      }
    });
    
    if (existingLanguage && existingLanguage.length > 0) {
      alert("Please Choose another language, This Language Video already exist.");
      return false;
    }
    setInputData({ ...inputData, selectedLan: value });
  };
  const handleChangeLanguages = (value) => {
    let existingLanguage: any = [];
    allLanguagesPdf.forEach((item: any) => {
      if (item.languageId === value) {
        existingLanguage = [...existingLanguage, item];
        return existingLanguage;
      }
    });
    if (existingLanguage && existingLanguage.length > 0) {
      // alert("Please Choose another language, This Language Video already exist.");
      Snackbar.show("Please Choose another language, This Language Pdf already exist.", "error");

      return false;
    }
    setRowData({ ...rowData, selectedLan: value });
  };
  const handleSavePdf = (e) => {
    if (e.target.files && e.target.files[0]) {
      let isValid = isValidPdf(e.target.files[0].name);
      if (!isValid) {
        return false;
      }
      setPdfContent(e.target.files[0]);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      setFilePreview(URL.createObjectURL(e.target.files[0]));
      setPdfContent(formData);
    }
  };
  const handleSaveImage = (e) => {
    if (e.target.files && e.target.files[0]) {
      let isValid = isValidImage(e.target.files[0].name);
      if (!isValid) {
        return false;
      }
      setImageContent(e.target.files[0]);
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      setImagePreview(URL.createObjectURL(e.target.files[0]));
      setImageContent(formData);
    }
  };

  return (
    <div>
      <Grid container xs={12} sm={12} md={12} className={classes.root} justifyContent="center">
        <CssBaseline />
        <CustomSelect
          disabled={parentArtifactId || (data.courseQuestionList && data.courseQuestionList.length > 0)}
          field="optionId"
          selectedValue={contentType}
          label="Options"
          data={optionArray}
          handleStateChange={(field, value) => handleStateChange(field, value)}
        />
      </Grid>
      {renderContent()}
    </div>
  );
};

export default SubSectionContent;
